/* eslint-disable react/destructuring-assignment */
import React from 'react'
import Head from 'next/head'
import { appWithTranslation } from 'next-i18next'
import '../src/styles/index.scss'
import LoadingIndicator from '../src/components/common/Layout/LoadingIndicator'
// import '../src/lib/antd/antd.css'

const MyApp = (props) => {
  const { Component, pageProps } = props
  return (
    <>
      <Head>
        <title>Sim Travel</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <title>Sim Travel</title>
        <meta
          name="description"
          content="SimPlus"
        />
        <meta itemProp="name" content="Sim Travel" />
        <meta
          itemProp="description"
          content="SimPlus"
        />
        <meta name="twitter:title" content="Sim Travel" />
        <meta
          name="twitter:description"
          content="SimPlus"
        />
        <meta name="twitter:card" content="summary_large_image" />

        <meta property="og:title" content="Sim Travel" />
        <meta
          property="og:description"
          content="SimPlus"
        />
        
        <link async
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
        />
        <link rel="shortcut icon" href="/images/common/favicon.png" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
      </Head>
      <LoadingIndicator />
      <Component {...pageProps} />
    </>
  )
}

export default appWithTranslation(MyApp)
